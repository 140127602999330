const Layout2Settings = {
    mode: 'contained',
    topbar: {
        show: true,
        fixed: true,
        theme: 'slateDark1',
    },
    navbar: {
        show: true,
        theme: 'slateDark2',
    },
    leftSidebar: {
        show: true,
        mode: 'close', 
        theme: 'slateDark1',
    },
}

export default Layout2Settings
