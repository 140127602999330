// Change API code before going PRODUCTION
// export const API_URI ="https://rmapi.credentialmanagement.com.au"
let API_URI;
let VERSION= "0.1.7";
let ENV="PRODUCTION"; //PRODUCTION // TESTING


if(ENV==="TESTING"){
    API_URI ="http://localhost:8001"
}else{
    API_URI ="https://rmapi.credentialmanagement.com.au"
}

export {
    API_URI,
    VERSION,
    ENV
}