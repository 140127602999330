import React from 'react'

const MatxLogo = ({ className }) => {

    return  (
        <img
            alt="MR LOGO"
            src="/assets/images/logos/mr-logo.svg"
            style={{
                margin:'5px',
                width:"100px",
                height:"50px",
                borderRadius:25
            }}
        />
    )
}

export default MatxLogo
