import React, { lazy } from 'react'
import Loadable from 'app/components/Loadable/Loadable';
import CustomerAwaitingList from './customers/CustomerAwaitingList';
import ApprovedCustomer from './customers/ApprovedCustomerList';
const PreviouslyReceivedPasses = Loadable(lazy(() => import("./customers/MembersPreviouslyReceived")));
const AddWallet = Loadable(lazy(() => import("./wallet/AddWallet")));
const WalletList = Loadable(lazy(()=>import("./wallet/WalletList")));
const CustomerList = Loadable(lazy(() => import("./customers/CustomerList")));
const CustomerForm = Loadable(lazy(() => import("./customers/customer-form/CustomerForm")));
const CustomerViewer = Loadable(lazy(() => import("./customers/customer-viewer/CustomerViewer")));
const ClientForm = Loadable(lazy(()=>import("./clients/client-form/ClientForm")))
const ClientList = Loadable(lazy(()=>import("./clients/ClientList")))
const ClientDetails = Loadable(lazy(()=>import('./clients/client-viewer/ClientViewer')))   
const ClientMemberList = Loadable(lazy(()=>import('./clients/clientMemberList'))) 
const WalletManagement = Loadable(lazy(()=>import("./wallet/WalletManagement")))
const MembershipType = Loadable(lazy(()=>import("../pages/membershipTypes/membershipTypesList")))
const BulkPassManagement = Loadable(lazy(()=>import("./wallet/BulkWalletManagement")))
const AllLogs = Loadable(lazy(()=>import("../pages/Logs/AllLogs")))
const UploadMember = Loadable(lazy(()=>import("../pages/customers/UploadMember")))


const pagesRoutes = [
    {
        path: 'pages/membership-types',
        element: <MembershipType />
    },
    {
        path: 'pages/upload-member',
        element: <UploadMember />
    },
    {
        path:"/pages/new-client/:id",
        element:<ClientForm/>
    },
    {
        path:"/pages/view-client/:id",
        element:<ClientDetails/>
    },
    {
        path:"/pages/client-member-list/:id",
        element:<ClientMemberList />
    },
    {
        path:"/pages/client-list",
        element:<ClientList/>
    },
    {
        path: '/pages/wallet-pass/:id',
        element: <AddWallet />,
    },
    {
        path: '/pages/all-wallet-pass',
        element: <WalletList />,
    },
    {
        path: '/pages/customer-list',
        element: <CustomerList condition='all' />,
    },
    {
        path: '/pages/members-received-passes',
        element: <PreviouslyReceivedPasses  />,
    },
    {
        path: '/pages/passes-awaiting-completion',
        element: <CustomerAwaitingList condition='pending' />
    },
    {
        path: '/pages/members-awaiting-pass',
        element: <ApprovedCustomer condition='current' />
    },
    {
        path: '/pages/new-customer/:id',
        element: <CustomerForm />,
    },
    {
        path: '/pages/view-customer/:id',
        element: <CustomerViewer />,
    },
    {
        path: '/pages/wallet/wallet-management',
        element: <WalletManagement />
    },
    {
        path:'/pages/bulk-pass-sender',
        element: <BulkPassManagement />
    },
    {
        path:'/pages/log-in-monitering',
        element: <AllLogs />
    }
]

export default pagesRoutes
